const BASE_PATH = 'https://agency-ux.ru/rest/1/2k2g9s0geqikosf3/';

export default class getApi {
    // кастомное апи
    static async elementGetList(data) {
        const SEARCH_PATH = 'iblock.Element.getList';
        const SEARCH_PARAM = new URLSearchParams(data);
        
        const response = await fetch(`${BASE_PATH}${SEARCH_PATH}?${SEARCH_PARAM.toString()}`);
        const listRes = response.json();
        return listRes;
    }
    static async addElement(data) {
        const SEARCH_PATH = 'iblock.Element.add';
        const SEARCH_PARAM = `iblockId=75&fields[NAME]=${data.name}&fields[PHONE]=${data.phone}&fields[EMAIL]=${data.email}&fields[SERVICE]=${data.service}`;
        const response = await fetch(`${BASE_PATH}${SEARCH_PATH}?${SEARCH_PARAM}`);
        const result = response.json();
        return result;
    }
}

