import React from "react";
import './Header.css';
import { images } from "../../constants";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <header className={'header'}>
            <Link to="/">
                <img src={images.logo} alt="" />
            </Link>
        </header>
    )
}
export default Header;