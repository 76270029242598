import React from "react";
import './InfoCompany.css'

const InfoCompany = () => {
    return (
        <section className="section-block section-block--custom">
            <div className="info-company">
                <div className="info-company__title">Digital-агентство,</div>
                <div className="info-company__desc">в котором команда идет к одной цели - способствовать развитию вашего бизнеса</div>
                <div className="info-company__image">
                    <div class="spinning-text">
                        <div class="spinning-text__image">
                            <img src="https://agency-ux.ru/local/templates/aspro-allcorp3digital/images/spinning-text/1.png" data-src="" alt="Агентство digital-маркетинга Agency-UX" />
                        </div>
                        <div class="spinning-text__image">
                            <img src="https://agency-ux.ru/local/templates/aspro-allcorp3digital/images/spinning-text/2.png" data-src="" alt="Агентство digital-маркетинга Agency-UX" />
                        </div>
                        <div class="spinning-text__image">
                            <img src="https://agency-ux.ru/local/templates/aspro-allcorp3digital/images/spinning-text/3.png" data-src="" alt="Агентство digital-маркетинга Agency-UX" />
                        </div>
                        <div class="spinning-text__image">
                            <img src="https://agency-ux.ru/local/templates/aspro-allcorp3digital/images/spinning-text/4.png" data-src="" alt="Агентство digital-маркетинга Agency-UX" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default InfoCompany;