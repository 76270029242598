import React from "react";
import MainServiceList from "../components/MainServiceList/MainServiceList";
import InfoCompany from "../components/InfoCompany/InfoCompany";
import MainSlider from "../components/MainSlider/MainSlider";

const Main = () => {
    return (
        <div>
            <MainSlider />
            <InfoCompany />
            <MainServiceList />
        </div>
    );
}
export default Main;