import {useState, useEffect} from "react";
import './CaseDetail.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import getApi from "../hooks/getApi";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const CaseDetail = () => {
    const params = useParams();
    const [caseDetail, setCase] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchCase = async () => {
            const response = await getApi.elementGetList({id: 51, element: params.id});
            setCase(response.result.data[0]);
        }
        fetchCase();
    }, [])
    return (
        <section className="section-block">
            <div className="case-detail">
                <div className="case-detail__image">
                    <button className="btn-mini" onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path d="M5.13232 1.68524L9.59353 6.14645C9.78879 6.34171 9.78879 6.65829 9.59353 6.85355L5.13232 11.3148" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </button>
                    <img src={caseDetail.PREVIEW_PICTURE} alt={caseDetail.NAME} />
                </div>
                <div className="case-detail__title">{caseDetail.NAME || <Skeleton/>}</div>
                <Link to={caseDetail.PROPERTY_LINK_VALUE} className="case-detail__link">{caseDetail.PROPERTY_LINK_VALUE || <Skeleton/>}</Link>
                {caseDetail.PROPERTY_TAGS_APP_VALUE &&
                    <div className="case-detail__tags">
                        {caseDetail.PROPERTY_TAGS_APP_VALUE.map(item => {
                            return <span>{item}</span>
                        })}
                    </div>
                }
                <div className="case-detail__content" dangerouslySetInnerHTML={{__html: caseDetail.PROPERTY_DETAIL_TEXT_APP_VALUE || <Skeleton  count={5}/>}} />
            </div>
        </section>
    );
}
export default CaseDetail;