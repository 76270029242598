import React from "react";
import './NavButton.css'
import { NavLink } from "react-router-dom";

const NavButton = (button) => {
    return (
        <NavLink 
            to={button.link} 
            className={({ isActive }) => (isActive ? 'navigation-button active' : 'navigation-button')}
        >
            <img src={button.image} />
            <span>{button.name}</span>
        </NavLink>
    )
}
export default NavButton;