import React, { useState, useEffect } from "react";
import './MainSlider.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Slide from '../Slide/Slide'
import getApi from "../../hooks/getApi";

const MainSlider = () => {
    const [slides, setSlides] = useState([]);
    useEffect(() => {
        const fetchSlides = async () => {
            const response = await getApi.elementGetList({id: 76});
            setSlides(response.result.data);
        }
        fetchSlides();
    }, [])
    return (
        <div className="slider">
            <Swiper
                className={'slider__wrapper'}
                spaceBetween={0}
                slidesPerView={1}
                pagination={true} 
                speed={1000}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
            >
                {slides.map(item => {
                    return <SwiperSlide>
                        <Slide key={item.ID}
                        name={item.NAME}
                        text={item.PREVIEW_TEXT}
                        image={item.PREVIEW_PICTURE}
                        tag={item.PROPERTY_TAG_VALUE}
                        id={item.ID}/>
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    )
}
export default MainSlider;