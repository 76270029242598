import {useState, useEffect} from "react";
import './ServiceDetail.css'
import { Link, useParams, useNavigate } from "react-router-dom";
import getApi from "../hooks/getApi";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const ServiceDetail = () => {
    const params = useParams();
    const [serviceDetail, setService] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchService = async () => {
            const response = await getApi.elementGetList({id: 74, element: params.id});
            setService(response.result.data[0]);
        }
        fetchService();
    }, [])
    console.log(serviceDetail);
    return (
        <section className="section-block">
            <div className="service-detail">
                <div className="service-detail__image">
                    <button className="btn-mini" onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path d="M5.13232 1.68524L9.59353 6.14645C9.78879 6.34171 9.78879 6.65829 9.59353 6.85355L5.13232 11.3148" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </button>
                    <img src={serviceDetail.PREVIEW_PICTURE} alt={serviceDetail.NAME} />
                </div>
                <div className="service-detail__title">{serviceDetail.NAME || <Skeleton/>}</div>
                <div className="service-detail__content" dangerouslySetInnerHTML={{__html: serviceDetail.DETAIL_TEXT || <Skeleton  count={5}/>}}/>
                <div className="service-detail__footer">
                    <div className="service-detail__price">
                        <span>Цена</span>
                        <span>{serviceDetail.PROPERTY_PRICE_VALUE} <i>₽</i></span>
                    </div>
                    <button className="service-detail__button" onClick={() => navigate(`/form/${params.id}`)}>Заказать</button>
                </div>
            </div>
        </section>
    );
}
export default ServiceDetail;