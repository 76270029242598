import React from "react";
import './Navigation.css';
import { images } from "../../constants";
import NavButton from "../NavButton/NavButton";

const Navigation = (props) => {
    return (
        <div className={'navigation'}>
            <NavButton 
                name={'Контакты'} 
                image={images.contacts}
                link={'/contacts'}
            />
            <NavButton 
                name={'Услуги'} 
                image={images.services}
                link={'/services'}
            />
            <NavButton 
                name={'Главная'} 
                image={images.home}
                link={'/'}
            />
            <NavButton 
                name={'Кейсы'} 
                image={images.cases}
                link={'/cases'}
            />
            <NavButton 
                name={'О компании'} 
                image={images.info}
                link={'/about-company/'}
            />
        </div>
    )
}
export default Navigation;