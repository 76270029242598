import { useState, useEffect } from "react";
import './AboutCompany.css'
import { images } from "../constants";

const AboutCompany = () => {
    return (
        <section className="section-block">
            <div className="section-block__title">О компании</div>
            <div className="section-block__image">
                <img src={images.infoCompany} alt="" />
            </div>
            <div className="info-company">
                <p>Команда Agency UX - выпускники лучших вузов с опытом работы в крупных компаниях, которые объединились для создания качественных IT-продуктов и маркетинговых исследований. 4 года назад мы запустили стартап и с тех пор вышли на уровень успешно функционирующего агентства с реализованными проектами и кейсами. Гибкость, оперативность, качество и системность - наши ключевые преимущества. Сейчас в нашей команде более 15 человек, которые каждый день помогают малому и среднему бизнесу развиваться и увеличивать прибыль.</p>
            </div>
        </section>
    );
}
export default AboutCompany;