import { useState, useEffect } from "react";
import ServiceCard from "../components/ServiceCard/ServiceCard";
import getApi from "../hooks/getApi";
import { useSearchParams } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const Services = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [services, setServices] = useState();
    const [filter, setFilter] = useState(services);
    const [toggleState, setToggleState] = useState('Все');

    let filters = ["Все", "Маркетинг", "Упаковка бизнеса", "Разработка"];

    useEffect(() => {
        const fetchServices = async () => {
            const response = await getApi.elementGetList({id: 74});
            setServices(response.result.data);
            setFilter(response.result.data);
        }
        if(!services){
            fetchServices();
        }else{
            if(searchParams.get("tag")){
                setToggleState(searchParams.get("tag"));
                let urlTag = [...services].filter(item => item.PROPERTY_TYPE_VALUE === searchParams.get("tag"));
                setFilter(urlTag);
            }
        }
    }, [services])

    function serviceFilter(select) {
        setToggleState(select);
        if(select === 'Все'){
            setFilter(services);
        }else{
            let newServices = [...services].filter(item => item.PROPERTY_TYPE_VALUE === select);
            setFilter(newServices);
        }
    }

    return (
        <section className="section-block">
            <div className="section-block__title">Услуги</div>
            <div className="section-block__tags">
                <Swiper
                    className={'slider__wrapper'}
                    spaceBetween={8}
                    slidesPerView={'auto'}
                    pagination={true} 
                    speed={1000}
                    modules={[FreeMode]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {filters.map((tag, idx) => (
                        <SwiperSlide 
                            className={toggleState === tag ? 'tag-slide active': 'tag-slide'}
                            onClick={ () => serviceFilter(tag)}
                            key={`tag-${idx}`}
                        >
                            {tag}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {filter?.map(item => {
                return <ServiceCard
                    key={item.ID}
                    name={item.NAME}
                    image={item.PREVIEW_PICTURE}
                    id={item.ID}
                    price={item.PROPERTY_PRICE_VALUE}
                    text={item.PREVIEW_TEXT}
                    stiker={item.PROPERTY_STIKER_NOTE_VALUE}
                />
            })}
        </section>
    );
}
export default Services;