import React from "react";
import './Loading.css';
import { images } from "../../constants";

const Loading = () => {
    return (
        <div className="loading">
            <img className="loading__logo" src={images.logo} alt="" />
            <img className="loading__logoApp" src={images.logoApp} alt="" />
            <span>Telegram mini-app</span>
        </div>
    )
}
export default Loading;