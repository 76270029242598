import React from "react";
import './CaseCard.css'
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const CaseCard = (props) => {
    const navigate = useNavigate();

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    })

    return (
        <div ref={ref} className="case-card">
            {
                inView 
                ? <img src={props.image} alt={props.name} />
                : <Skeleton height={200}/>
            }
            
            <div className="case-card__content">
                <div className="case-card__info">
                    <div className="case-card__group">
                        <div className="case-card__type">Разработка</div>
                        <div className="case-card__name">{props.name || <Skeleton/>}</div>
                    </div>
                    <button className="btn-mini" onClick={() => navigate(`/cases/${props.id}`)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path d="M5.13232 1.68524L9.59353 6.14645C9.78879 6.34171 9.78879 6.65829 9.59353 6.85355L5.13232 11.3148" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default CaseCard;