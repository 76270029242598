import { lazy, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';

import Main from './pages/Main';
import Cases from './pages/Cases';
import CaseDetail from './pages/CaseDetail';
import Services from './pages/Services';
import ServiceDetail from './pages/ServiceDetail';
import Form from './components/Form/Form';
import Contacts from './pages/Contacts';
import AboutCompany from './pages/AboutCompany';
import Loading from "./components/Loading/Loading";

import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useTelegram } from "./hooks/useTelegram";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);
    return (
        <div>
            { isLoading ? (
                <Loading />
            ) : (
                <SkeletonTheme baseColor="#212121" highlightColor="#FA6E37" borderRadius={16}>
                    <BrowserRouter>
                        <Header />
                        <Navigation />
                        <Routes>
                            <Route path='/' element={<Main />}/>
                            <Route path='/contacts/' element={<Contacts />}/>
                            <Route path='/about-company/' element={<AboutCompany />}/>
                            <Route exact path='/services' element={<Services />}/>
                            <Route exact path='/services/:id' element={<ServiceDetail />}/>
                            <Route exact path='/cases' element={<Cases />}/>
                            <Route exact path='/cases/:id' element={<CaseDetail />}/>
                            <Route exact path='/form/:id' element={<Form/>}/>
                            <Route
                                path="*"
                                element={<Navigate to="/" replace />}
                            />
                        </Routes>
                    </BrowserRouter>
                </SkeletonTheme>
            )}
        </div>
    );
}

export default App;