import React from "react";
import './ServiceCard.css'
import { useNavigate } from 'react-router-dom';

const ServiceCard = (props) => {
    const navigate = useNavigate();
    return (
        <div className="service-card">
            <div className="service-card__image">
                <img src={props.image} alt={props.name} />
            </div>
            <div className="service-card__content">
                <div className="service-card__group">
                    <div className="service-card__name">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.24719 6.81903H8.74719M10.2472 6.81903H8.74719M14.2472 6.81903H15.7472M17.2472 6.81903H15.7472M8.74719 6.81903V9.31903C8.74719 11.252 10.3142 12.819 12.2472 12.819V12.819C14.1802 12.819 15.7472 11.252 15.7472 9.31903V6.81903M20.2865 4V19.3785C20.2865 19.9308 19.8387 20.3785 19.2865 20.3785H5C4.44772 20.3785 4 19.9308 4 19.3785V4C4 3.44772 4.44771 3 5 3H19.2865C19.8387 3 20.2865 3.44772 20.2865 4Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        {props.name}
                    </div>
                    <button className="service-card__link" onClick={() => navigate(`/services/${props.id}`)}>Подробнее
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none">
                            <path d="M5 4.5C4.99561 4.26709 4.9165 4.08252 4.72754 3.89795L1.41846 0.663574C1.27783 0.527344 1.11523 0.457031 0.913086 0.457031C0.508789 0.457031 0.174805 0.786621 0.174805 1.18652C0.174805 1.38867 0.258301 1.57324 0.412109 1.72705L3.27734 4.49561L0.412109 7.27295C0.258301 7.42236 0.174805 7.60693 0.174805 7.81348C0.174805 8.21338 0.508789 8.54297 0.913086 8.54297C1.11084 8.54297 1.27783 8.47705 1.41846 8.33643L4.72754 5.10205C4.9165 4.91748 5 4.72852 5 4.5Z"/>
                        </svg>
                    </button>
                </div>
                <div className="service-card__price">
                    {props.price &&
                        <span>{props.price} <i>₽</i></span>
                    }
                    <button className="service-card__button" onClick={() => navigate(`/form/${props.id}`)}>Заказать</button>
                </div>
                {props.stiker &&
                    <div className="service-card__stiker">{props.stiker}</div>
                }
                {props.text &&
                    <div className="service-card__text">{props.text}</div>
                }
            </div>
        </div>
    )
}
export default ServiceCard;