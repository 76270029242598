import { useState, useEffect } from "react";
import CaseCard from "../components/CaseCard/CaseCard";
import getApi from "../hooks/getApi";

const Cases = () => {
    const [cases, setCases] = useState([])
    useEffect(() => {
        const fetchCases = async () => {
            const response = await getApi.elementGetList({id: 51});
            setCases(response.result.data);
        }
        fetchCases();
    }, [])
    return (
        <section className="section-block">
            <div className="section-block__title">Кейсы</div>
            {cases.map(item => {
                return <CaseCard
                    key={item.ID}
                    name={item.NAME}
                    image={item.PREVIEW_PICTURE}
                    id={item.ID}
                />
            })}
        </section>
    );
}
export default Cases;