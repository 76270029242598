import React from "react";
import { useNavigate } from 'react-router-dom';
import './Slide.css'

const Slide = (props) => {
    const navigate = useNavigate();
    return (
        <div className="slide">
            <img src={props.image} alt={props.name} />
            <div className="slide__content">
                <div className="slide__group">
                    <div className="slide__title">{props.name}</div>
                    {props.tag &&
                        <button className="btn-mini" onClick={() => navigate(`/services?tag=${props.tag}`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                <path d="M5.13232 1.68524L9.59353 6.14645C9.78879 6.34171 9.78879 6.65829 9.59353 6.85355L5.13232 11.3148" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </button>
                    }
                </div>
                <div className="slide__desc">{props.text}</div>
            </div>
        </div>
    )
}
export default Slide;