import { useState, useEffect } from "react";
import './Contacts.css'
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

const Contacts = () => {
    return (
        <section className="section-block">
            <div className="section-block__title">Контакты</div>
            <div className="contact-block">
                <div className="contact-block__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M6 11.607C6 8.234 8.686 5.5 12 5.5C15.314 5.5 18 8.234 18 11.607C18 14.954 16.085 18.859 13.097 20.256C12.7536 20.4166 12.3791 20.4998 12 20.4998C11.6209 20.4998 11.2464 20.4166 10.903 20.256C7.915 18.859 6 14.954 6 11.607Z" stroke="#FA6E37" stroke-width="1.5"/>
                        <path d="M14 11.5C14 12.0304 13.7893 12.5391 13.4142 12.9142C13.0391 13.2893 12.5304 13.5 12 13.5C11.4696 13.5 10.9609 13.2893 10.5858 12.9142C10.2107 12.5391 10 12.0304 10 11.5C10 10.9696 10.2107 10.4609 10.5858 10.0858C10.9609 9.71071 11.4696 9.5 12 9.5C12.5304 9.5 13.0391 9.71071 13.4142 10.0858C13.7893 10.4609 14 10.9696 14 11.5Z" stroke="#FA6E37" stroke-width="1.5"/>
                    </svg>
                </div>
                <div className="contact-block__content">
                    <div className="contact-block__title">Адрес</div>
                    <div className="contact-block__info">г. Москва, ул. Верейская 25, офис 211</div>
                </div>
            </div>
            <div className="contact-block">
                <div className="contact-block__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1645 5.18608C6.22539 4.13961 7.97231 4.32556 8.86059 5.50348L9.9607 6.95989C10.6842 7.91814 10.6197 9.25693 9.76108 10.1036L9.55361 10.3094C9.53009 10.3959 9.52769 10.4866 9.54664 10.5741C9.60156 10.9269 9.89881 11.6742 11.1436 12.9023C12.3884 14.1303 13.1468 14.4244 13.5077 14.4797C13.5987 14.4979 13.6926 14.4952 13.7823 14.472L14.138 14.1208C14.9016 13.3684 16.0732 13.2274 17.0181 13.7368L18.6831 14.6363C20.1101 15.4043 20.4702 17.3277 19.3021 18.4805L18.0633 19.7017C17.6728 20.0866 17.148 20.4074 16.5082 20.4671C14.9304 20.6133 11.2543 20.4264 7.39 16.6151C3.7837 13.0571 3.09155 9.95399 3.00351 8.42494C2.95992 7.65177 3.32779 6.99794 3.79678 6.53611L5.1645 5.18608ZM7.81453 6.28098C7.37257 5.69548 6.54967 5.64878 6.08591 6.10628L4.71731 7.45544C4.42964 7.73911 4.29191 8.05219 4.30935 8.35143C4.37908 9.56654 4.93698 12.3661 8.31228 15.6957C11.8532 19.188 15.123 19.2926 16.387 19.175C16.6451 19.1517 16.9013 19.0185 17.1411 18.7824L18.3789 17.5603C18.8828 17.0639 18.772 16.1593 18.059 15.7753L16.394 14.8767C15.9337 14.6294 15.395 14.7107 15.0603 15.041L14.6636 15.4328L14.2016 14.9727C14.6636 15.4328 14.6628 15.4337 14.6619 15.4337L14.661 15.4354L14.6584 15.438L14.6523 15.4432L14.6392 15.4553C14.6024 15.4892 14.5628 15.5199 14.5207 15.547C14.4509 15.5928 14.3585 15.6438 14.2426 15.6862C14.0072 15.7736 13.6952 15.8203 13.3099 15.7615C12.5541 15.6464 11.5525 15.1353 10.2213 13.8225C8.8911 12.5096 8.37156 11.522 8.25475 10.773C8.1946 10.3907 8.24255 10.0811 8.33146 9.84762C8.3804 9.71622 8.45046 9.59355 8.53893 9.48438L8.56682 9.45411L8.57903 9.44114L8.58426 9.43595L8.58687 9.43335L8.58862 9.43162L8.83967 9.18428C9.21277 8.81499 9.26507 8.20354 8.91377 7.73739L7.81453 6.28098Z" fill="#FA6E37"/>
                    </svg>
                </div>
                <div className="contact-block__content">
                    <div className="contact-block__title">Телефон</div>
                    <div className="contact-block__info"><a href="tel:+7 (995) 960-50-70">+7 (995) 960-50-70</a></div>
                </div>
            </div>
            <div className="contact-block">
                <div className="contact-block__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9491 7.51198C12.4077 7.51198 12.7794 7.88369 12.7794 8.34223V12.2233H16.8715C17.33 12.2233 17.7018 12.595 17.7018 13.0536C17.7018 13.5121 17.33 13.8838 16.8715 13.8838H11.1189V8.34223C11.1189 7.88369 11.4906 7.51198 11.9491 7.51198ZM11.9999 19.142C15.6682 19.142 18.6419 16.1683 18.6419 12.5001C18.6419 8.83182 15.6682 5.85812 11.9999 5.85812C8.3317 5.85812 5.358 8.83182 5.358 12.5001C5.358 16.1683 8.3317 19.142 11.9999 19.142ZM11.9999 20.8025C16.5852 20.8025 20.3024 17.0854 20.3024 12.5001C20.3024 7.91476 16.5852 4.19763 11.9999 4.19763C7.41463 4.19763 3.69751 7.91476 3.69751 12.5001C3.69751 17.0854 7.41463 20.8025 11.9999 20.8025Z" fill="#FA6E37"/>
                    </svg>
                </div>
                <div className="contact-block__content">
                    <div className="contact-block__title">Режим работы</div>
                    <div className="contact-block__info">Пн - Пт: 10.00 - 20.00</div>
                </div>
            </div>
            <div className="contact-block">
                <div className="contact-block__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M20 12.5001C20 10.8286 19.4765 9.19897 18.5028 7.84022C17.5292 6.48148 16.1544 5.46185 14.5715 4.92455C12.9886 4.38724 11.2772 4.35926 9.6776 4.84451C8.07801 5.32977 6.6706 6.3039 5.65304 7.63008C4.63549 8.95626 4.0589 10.5679 4.00428 12.2386C3.94965 13.9092 4.41972 15.5551 5.34847 16.9449C6.27721 18.3347 7.61798 19.3987 9.18246 19.9874C10.7469 20.5761 12.4565 20.66 14.0711 20.2273" stroke="#FA6E37" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M11.9999 16.0557C13.9636 16.0557 15.5554 14.4638 15.5554 12.5001C15.5554 10.5365 13.9636 8.94458 11.9999 8.94458C10.0362 8.94458 8.44434 10.5365 8.44434 12.5001C8.44434 14.4638 10.0362 16.0557 11.9999 16.0557Z" stroke="#FA6E37" stroke-width="1.5"/>
                        <path d="M15.5557 9.8335V13.8335C15.5557 14.4229 15.7898 14.9881 16.2065 15.4048C16.6233 15.8216 17.1885 16.0557 17.7779 16.0557C18.3673 16.0557 18.9325 15.8216 19.3492 15.4048C19.766 14.9881 20.0001 14.4229 20.0001 13.8335V12.5002" stroke="#FA6E37" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                </div>
                <div className="contact-block__content">
                    <div className="contact-block__title">E-mail</div>
                    <div className="contact-block__info"><a href="mailTo:info@agency-ux.ru">info@agency-ux.ru</a></div>
                </div>
            </div>
            <div className="contact-map">
                <YMaps>
                    <Map width="100%" height="343px" 
                        defaultState={{ 
                            center: [55.711432, 37.439904], 
                            zoom: 15, 
                            controls: [],
                        }}
                    >
                        <Placemark 
                            defaultGeometry={[55.711432, 37.439904]}
                        />
                    </Map>
                </YMaps>
            </div>
        </section>
    );
}
export default Contacts;