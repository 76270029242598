import logo from "../assets/images/logo.svg";
import logoApp from "../assets/images/logo-app.svg";
import contacts from "../assets/images/icon-contacts.svg";
import services from "../assets/images/icon-services.svg";
import info from "../assets/images/icon-info.svg";
import cases from "../assets/images/icon-cases.svg";
import home from "../assets/images/icon-home.svg";
import infoCompany from "../assets/images/about-image.png";
export default { 
    home,
    cases,
    info,
    infoCompany,
    services,
    contacts,
    logo,
    logoApp,
}