import React, { useCallback, useEffect, useState } from "react";
import './Form.css'
import { useNavigate, useParams } from "react-router-dom";
import getApi from "../../hooks/getApi";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { useTelegram } from "../../hooks/useTelegram";

const Form = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { tg, user } = useTelegram();

    const [serviceDetail, setService] = useState([]);
    useEffect(() => {
        const fetchService = async () => {
            const response = await getApi.elementGetList({id: 74, element: params.id});
            setService(response.result.data[0]);
        }
        fetchService();
    }, [])

    const {
        register,
        formState: {
            errors,
            isValid
        },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            name: user ? user?.first_name + ' ' + user?.last_name : ''
        }
    });
    const [result, setResult] = useState('');

    const onSubmit = (data) => {
        data.service = serviceDetail.NAME;
        setResult(getApi.addElement(data));
        reset();
        tg.sendData(JSON.stringify(data));
    }
    
    return (
        <div className="form-page">
            <button className="form-page__link" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none">
                    <path d="M-3.53448e-07 4.5C0.0043942 4.73291 0.0834958 4.91748 0.272461 5.10205L3.58154 8.33643C3.72217 8.47266 3.88477 8.54297 4.08691 8.54297C4.49121 8.54297 4.8252 8.21338 4.8252 7.81348C4.8252 7.61133 4.7417 7.42676 4.58789 7.27295L1.72266 4.50439L4.58789 1.72705C4.7417 1.57764 4.82519 1.39307 4.82519 1.18652C4.82519 0.786621 4.49121 0.457031 4.08691 0.457031C3.88916 0.457031 3.72217 0.522949 3.58154 0.663574L0.272461 3.89795C0.0834957 4.08252 -3.73425e-07 4.27148 -3.53448e-07 4.5Z" fill="white"/>
                </svg>
                Назад
            </button>
            {
                result
                ? (
                    <div className="form-page__success">
                        <div className="form-page__content">
                            <div className="form-page__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none">
                                    <path d="M36.5 66.9167C44.8993 66.9167 52.5034 63.5121 58.0078 58.0078C63.5121 52.5034 66.9167 44.8993 66.9167 36.5C66.9167 28.1007 63.5121 20.4966 58.0078 14.9922C52.5034 9.48784 44.8993 6.08334 36.5 6.08334C28.1007 6.08334 20.4966 9.48784 14.9922 14.9922C9.48784 20.4966 6.08333 28.1007 6.08333 36.5C6.08333 44.8993 9.48784 52.5034 14.9922 58.0078C20.4966 63.5121 28.1007 66.9167 36.5 66.9167Z" stroke="#13AD99" stroke-width="2" stroke-linejoin="round"/>
                                    <path d="M24.3333 36.5L33.4583 45.625L51.7083 27.375" stroke="#13AD99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="form-page__title">Ваша заявка успешно принята!</div>
                            <div className="form-page__desc">Наши менеджеры свяжутся с вами в ближайшее время</div>
                        </div>
                        <div className="form-page__footer">
                            <a className="form-page__button" href="/">На главную</a>
                        </div>
                    </div>
                )
                : (
                    <div className="form-page__body">
                        <div className="form-page__title">Оставить заявку</div>
                        <div className="form-page__desc">Заполните данные и наши менеджеры свяжутся с вами в ближайшее время</div>
                        <form className="form-page__form" onSubmit={handleSubmit(onSubmit)} autocomplete="off">
                            <div className="form-page__content">
                                <div className="field">
                                    <label>Имя</label>
                                    <input 
                                        {...register('name', {
                                            required: 'Поле "Имя" не может быть пустым!',
                                            minLength: {
                                                value: 2,
                                                message: 'Поле "Имя" содержит меньше двух символов'
                                            },
                                        })}
                                    />
                                </div>
                                <div className="field">
                                    <label>Телефон</label>
                                    <InputMask
                                        mask="+7\(999) 999-99-99"
                                        maskChar="_"
                                        type="text"
                                        alwaysShowMask={false}
                                        maskPlaceholder=''
                                        {...register('phone', {
                                            required: 'Поле "Телефон" не может быть пустым!',
                                        })}
                                    >
                                    </InputMask>
                                </div>
                                <div className="field">
                                    <label>Электронная почта</label>
                                    <input 
                                        {...register('email', {
                                            required: 'Поле "Электронная почта" не может быть пустым!',
                                            pattern: {
                                                value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                                message: 'Поле "Электронная почта" заполнено не верно!'
                                            }
                                        })}
                                    />
                                </div>
                                
                                <div className="form-page__errors">
                                    {errors?.name && <p>Ошибка! {errors?.name?.message}</p>}
                                    {errors?.phone && <p>Ошибка! {errors?.phone?.message}</p>}
                                    {errors?.email && <p>Ошибка! {errors?.email?.message}</p>}
                                </div>
                            </div>
                            <div className="form-page__footer">
                                <input type="submit" className="form-page__button" value="Отправить" disabled={!isValid}/>
                            </div>
                        </form>
                    </div>
                )
            }
        </div>
    )
}
export default Form;