import { useState, useEffect } from "react";
import './MainServiceList.css'
import ServiceItem from "../ServiceItem/ServiceItem";
import getApi from "../../hooks/getApi";

const MainServiceList = () => {
    const [services, setServices] = useState([])
    useEffect(() => {
        const fetchServices = async () => {
            const response = await getApi.elementGetList({id: 74});
            setServices(response.result.data);
            console.log(response)
        }
        fetchServices();
    }, [])
    return (
        <section className="section-block">
            {services.map(item => {
                if(item.PROPERTY_VIEW_MAIN_VALUE){
                    return <ServiceItem
                        key={item.ID}
                        id={item.ID}
                        name={item.NAME}
                        image={item.PREVIEW_PICTURE}
                        linkedItem={item.ITEM_LINKED}//?
                        text={item.PROPERTY_TEXT_VALUE}
                        stiker={item.PROPERTY_STIKER_NOTE_VALUE}
                        title={item.PROPERTY_TITLE_VALUE}
                        color={item.PROPERTY_COLOR_CODE_VALUE}
                        icon={item.PROPERTY_ICON_VALUE}
                    />
                }
            })}
        </section>
    )
}
export default MainServiceList;