import React from "react";
import './ServiceItem.css'
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const ServiceItem = (props) => {
    const navigate = useNavigate();

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    })

    return (
        <div ref={ref} className="service-item">
            <div className="service-item__info" onClick={() => navigate(`/form/${props.id}`)}>
                <div className="service-info" style={{backgroundColor:props.color}}>
                    <div className="service-info__name">{props.title}</div>
                    <div className="service-info__note">{props.stiker}</div>
                    <div className="service-info__footer">
                        <div className="service-info__desc">{props.text}</div>
                        <div className="service-info__icon">
                            <img src={props.icon} alt={props.title} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-item__image">
                {
                    inView 
                    ? <img src={props.image} alt={props.name} />
                    : <Skeleton height={200}/>
                }
            </div>
            <div className="service-item__name">{props.name || <Skeleton/>}</div>
            {props.linkedItem &&
                <div className="service-item__footer">
                    <div className="service-item__case">
                        <div className="mini-case">
                            <div className="mini-case__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M11 7.5H7C6.44772 7.5 6 7.94772 6 8.5V17.5C6 18.0523 6.44772 18.5 7 18.5H21C21.5523 18.5 22 18.0523 22 17.5V8.5C22 7.94771 21.5523 7.5 21 7.5H17M11 7.5V4.5C11 3.94772 11.4477 3.5 12 3.5H16C16.5523 3.5 17 3.94772 17 4.5V7.5M11 7.5H17M2 11.5V21.5C2 22.0523 2.44772 22.5 3 22.5H19" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>
                            <div className="mini-case__content">
                                <div className="mini-case__title">Кейс для компании</div>
                                <div className="mini-case__name" onClick={() => navigate(`/cases/${props.linkedItem.ID}`)}>{props.linkedItem.NAME || <Skeleton/>}</div>
                            </div>
                        </div>
                    </div>
                    <button className="service-item__link" onClick={() => navigate(`/services/${props.id}`)}>Подробнее
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none">
                            <path d="M5 4.5C4.99561 4.26709 4.9165 4.08252 4.72754 3.89795L1.41846 0.663574C1.27783 0.527344 1.11523 0.457031 0.913086 0.457031C0.508789 0.457031 0.174805 0.786621 0.174805 1.18652C0.174805 1.38867 0.258301 1.57324 0.412109 1.72705L3.27734 4.49561L0.412109 7.27295C0.258301 7.42236 0.174805 7.60693 0.174805 7.81348C0.174805 8.21338 0.508789 8.54297 0.913086 8.54297C1.11084 8.54297 1.27783 8.47705 1.41846 8.33643L4.72754 5.10205C4.9165 4.91748 5 4.72852 5 4.5Z"/>
                        </svg>
                    </button>
                </div>
            }
        </div>
    )
}
export default ServiceItem;